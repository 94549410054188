<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">입찰 관리</h2>
      </div>
      <div class="body_section">
        <div id="wrapPrint" class="wrap_print">
          <div class="page_print">
            <AnnounceInfo :dataProps="postBoard" />
            <EvaluationSetting :dataProps="postEvaluate" />
            <SelectionPartnersInfo :dataList="postPersonList" />
            <ParticipatedCompanyClosed
              v-if="!isBidsOpened"
              :dataList="postTenderList"
              @onClickPopPartner="onClickPopPartner"
            />
            <ParticipatedCompanyOpened
              v-else
              :model="participatedCompanyOpenedModel"
              :isOnlyPriceEvaluate="isOnlyPriceEvaluate"
              :isBidChoiced="isBidChoiced"
              @onClickPopPartner="onClickPopPartner"
            />
          </div>
        </div>
        <OverallOpinion
          v-if="isBidsOpened"
          :model="overallOpinionModel"
          :isDisabled="isBidChoiced"
        />
        <AnnounceHistroy v-if="isBidChoiced" :postHistoryList="postHistoryList" />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button
          v-if="isGetDataApiComplete && !isAnnounceClosed"
          type="button"
          class="btn_primary btn_large"
          @click="onClickPopAnnounceClose"
        >
          마감
        </button>
        <button
          v-else-if="isGetDataApiComplete && !isBidsOpened"
          type="button"
          class="btn_primary btn_large"
          @click="onClickPopBidOpen"
        >
          개봉
        </button>
        <button
          v-else-if="isGetDataApiComplete && isBidsReOpened"
          type="button"
          class="btn_primary btn_large"
          @click="onClickAnnounce"
        >
          공고 바로가기
        </button>

        <button
          v-if="isGetDataApiComplete && isBidsOpened && !isBidChoiced"
          type="button"
          class="btn_secondary btn_large"
          @click="onClickSaveTemp"
        >
          임시저장
        </button>
        <button
          v-if="isGetDataApiComplete && isBidsOpened && !isBidChoiced"
          type="button"
          class="btn_primary btn_large"
          @click="onClickSave"
        >
          확정
        </button>
        <button
          v-if="isGetDataApiComplete && isBidsOpened"
          type="button"
          class="btn_tertiary btn_large"
          @click="print"
        >
          인쇄하기
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <!-- 기획서에 선정에 대한 팝업 없어서 주석 처리 -->
      <!-- <AlertPopup
        @onClickFourtyly='onClickClose'
        @onClickPrimary='onClickSelectionComplete'
        v-if='isSelectionPop'
        alertText="선택한 업체를 선정하시겠습니까?<br>*선정이 완료되면 평가 정보는 수정할 수 없습니다."
        btnFourthlyText="취소"
      btnPrimaryText="선정" />-->
      <BidPartnerPopup
        v-if="isPopPartnerInfo"
        :userNum="selectedUserNum"
        @onClickClose="onClickClose"
        @alert="alert"
      />
      <CloseAnnouncePopup
        v-if="isPopAnnounceClose"
        :loginIdWithName="myLoginIdWithName"
        @onClickClose="onClickClose"
        @onClickSubmit="onClickCloseAnnounce"
      />
      <ReleaseReasonPopup
        v-if="isPopBidOpen"
        :loginIdWithName="myLoginIdWithName"
        @onClickClose="onClickClose"
        @onClickSubmit="onClickOpenBids"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";

import AnnounceInfo from "@/components/admin/bid/view/AnnounceInfo";
import EvaluationSetting from "@/components/admin/bid/view/EvaluationSetting";
import SelectionPartnersInfo from "@/components/admin/bid/view/SelectionPartnersInfo";
import ParticipatedCompanyClosed from "@/components/admin/bid/view/ParticipatedCompanyClosed";
import ParticipatedCompanyOpened from "@/components/admin/bid/view/ParticipatedCompanyOpened";
import OverallOpinion from "@/components/admin/bid/view/OverallOpinion";
import AnnounceHistroy from "@/components/admin/bid/view/AnnounceHistroy";

import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import BidPartnerPopup from "@/components/admin/bid/popup/BidPartnerPopup";
import CloseAnnouncePopup from "@/components/admin/bid/popup/CloseAnnouncePopup";
import ReleaseReasonPopup from "@/components/admin/bid/popup/ReleaseReasonPopup";

import { Printd } from "printd";
import printBidCss from "@/constants/printBidCss.js"; // css 수정 필요

import PageMixin from "@/mixins/PageMixin";
import BidItemMixin from "@/mixins/bid/BidItemMixin";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { getIsOnlyPriceEvaluate } from "@/utils/spUtils";

import OverallOpinionModel from "@/components/admin/bid/view/OverallOpinionModel";
import ParticipatedCompanyOpenedModel from "@/components/admin/bid/view/ParticipatedCompanyOpenedModel";

export default {
  name: "BidView",
  components: {
    PageWithLayout,
    AnnounceInfo,
    EvaluationSetting,
    SelectionPartnersInfo,
    ParticipatedCompanyClosed,
    ParticipatedCompanyOpened,
    OverallOpinion,
    AnnounceHistroy,
    Sticky,
    AlertPopup,
    BidPartnerPopup,
    CloseAnnouncePopup,
    ReleaseReasonPopup,
  },
  mixins: [PageMixin, BidItemMixin],
  data() {
    return {
      selectedUserNum: null,

      isPopAnnounceClose: false,
      isPopBidOpen: false,
      isPopPartnerInfo: false,

      overallOpinionModel: new OverallOpinionModel(),
      participatedCompanyOpenedModel: new ParticipatedCompanyOpenedModel(),
    };
  },
  computed: {
    // 애초에 생각을 잘못해서 그냥 임시 방편으로 처리하겠다..
    isGetDataApiComplete() {
      return !!this.itemData.tenderStatus;
    },
    postBoard() {
      return this.itemData.postBoard || {};
    },
    postEvaluate() {
      return this.postBoard.postEvaluate || {};
    },
    postPersonList() {
      return this.postBoard.postPersonList || [];
    },
    postTenderList() {
      return this.itemData.postTenderList || [];
    },
    postHistoryList() {
      return this.itemData.postHistoryList || [];
    },
    isAnnounceClosed() {
      // S: 공고 중, B: 입찰 진행 중, C: 공고 마감.
      const status = this.postBoard.status;

      return status === "C";
    },
    isBidsOpened() {
      return this.itemData.tenderStatus !== "P";
    },
    isBidChoiced() {
      return this.itemData.tenderStatus === "S" || this.itemData.tenderStatus === "N";
    },
    isBidsReOpened() {
      return this.itemData.tenderStatus === "N";
    },
    isOnlyPriceEvaluate() {
      const { choiceType } = this.postEvaluate;

      return getIsOnlyPriceEvaluate(choiceType);
    },
    myLoginIdWithName() {
      return LocalStorageManager.shared.getUserLoginIdWithName();
    },
  },
  methods: {
    setDataFromServer(dataFromServer) {
      const { note, attachFileList, postBoard, postHistoryList, postTenderList } = dataFromServer;

      this.overallOpinionModel.setData(attachFileList, note);

      if (postTenderList) this.participatedCompanyOpenedModel.setData(postTenderList);
    },
    onClickAnnounce() {
      const { postCid } = this.postBoard;

      this.$router.push({
        path: `${this.$routerPath.ANNOUNCE_VIEW}/${postCid}`,
      });
    },
    onClickPopAnnounceClose() {
      this.isPopAnnounceClose = true;
    },
    onClickCloseAnnounce(text) {
      const { postCid } = this.postBoard;

      this.closeAnnounce(postCid, text);
    },
    async closeAnnounce(postCid, text) {
      const path = `${this.$apiPath.BID}/${postCid}${this.$apiPath.CLOSE}`;
      const userData = LocalStorageManager.shared.getUserData();
      const companyName = userData.companyName;

      const obj = {
        reason: text,
        name: companyName,
      };

      const result = await ApiService.shared.putData(path, obj);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.refresh();

      this.isPopAnnounceClose = false;
    },
    onClickPopBidOpen() {
      this.isPopBidOpen = true;
    },
    onClickOpenBids(text) {
      const { postCid } = this.postBoard;

      this.openBids(postCid, text);
    },
    async openBids(postCid, text) {
      const path = `${this.$apiPath.BID}/${postCid}${this.$apiPath.OPEN}`;

      const obj = {
        reason: text,
      };

      const result = await ApiService.shared.putData(path, obj);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.refresh();

      this.isPopBidOpen = false;
    },
    onClickSaveTemp() {
      const isTemp = true;
      const { postCid } = this.postBoard;

      this.saveChoice(isTemp, postCid);
    },
    onClickSave() {
      const isTemp = false;
      const { postCid } = this.postBoard;

      this.saveChoice(isTemp, postCid);
    },
    async saveChoice(isTemp, postCid) {
      const path = `${this.$apiPath.BID}/${postCid}${this.$apiPath.EVALUATE}?temp=${
        isTemp ? "Y" : "N"
      }`;

      let obj = this.overallOpinionModel.getData();

      const postTenderList = this.participatedCompanyOpenedModel.getData();
      obj.postTenderList = postTenderList;

      const result = await ApiService.shared.putData(path, obj);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.refresh();
      // 저장 성공 시 그냥 목록으로.
      // this.$router.push({
      //   path: this.$routerPath.BID_LIST
      // });
    },
    onClickClose() {
      this.isPopPartnerInfo = false;
      this.isPopAnnounceClose = false;
      this.isPopBidOpen = false;

      this.selectedPartner = null;
    },
    onClickPopPartner(item) {
      this.selectedUserNum = String(item.userNum);

      this.isPopPartnerInfo = true;
    },
    onClickGoToList() {
      this.goToList(true);
    },
    print() {
      const wrapPrint = document.getElementById("wrapPrint");
      const d = new Printd();

      // printd 라이브러리 버그로 인해 그냥 스트링을 가져오는 걸로 임시 처리. 추후 변경 필요.
      // 버그 내용: css를 읽었다 안읽었다 한다.
      // const styles = [
      //   '/assets/css/print.css'
      // ]

      const styles = [printBidCss];

      d.print(wrapPrint, styles);
    },
  },
};
</script>
