import ParticipatedCompanyOpenedItemModel from "./ParticipatedCompanyOpenedItemModel";

class ParticipatedCompanyOpenedModel {
  constructor() {
    this.dataList = [];
  }

  setData(arr) {
    this.dataList = arr.map((item) => {
      const itemModel = new ParticipatedCompanyOpenedItemModel();
      itemModel.setData(item);
      return itemModel;
    });
  }

  getData() {
    const ret = this.dataList.map((item) => item.getData());

    return ret;
  }

  getAlertMessage() {
    return null;
  }
}

export default ParticipatedCompanyOpenedModel;
