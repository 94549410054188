<template>
  <div class="area_view">
    <TableHead title="입찰 선정 업체 정보" />
    <Board :disableHover="true" :dataList="dataList" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 110px" />
        <col style="width: 224px" />
        <col style="width: 224px" />
        <col style="width: 224px" />
        <col style="width: 224px" />
        <col style="width: 234px" />
      </template>
      <template v-slot:tr>
        <th>입찰 참여 상태</th>
        <th>대상 업체</th>
        <th>파트너사 담당자</th>
        <th>담당 업무</th>
        <th>이메일</th>
        <th>휴대전화</th>
      </template>
      <template v-slot:rows>
        <template v-for="item in dataList">
          <tr :key="item.id">
            <td>
              <span :class="['badge_state', item.tenderStatus === 'F' ? 'on' : 'disabled']">{{
                item.tenderStatusName
              }}</span>
            </td>
            <td>{{ item.companyName }}</td>
            <td>{{ item | idWithNameByObj }}</td>
            <td>{{ item.job }}</td>
            <td class="td_ellip align_left" :data-ellip="item.email">
              {{ item.email }}
            </td>
            <td>{{ item.mobile }}</td>
          </tr>
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="6" class="td_empty">입찰 선정한 업체가 없습니다.</td>
        </tr>
      </template>
    </Board>
  </div>
</template>

<script>
// companyName: "888"
// email: "8@8"
// job: "8"
// name: "8"
// tel: "02-888-8888"
// tenderStatus: "F"
// tenderStatusName: "입찰"
// userNum: 32
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";

export default {
  name: "SelectionPartnersInfo",
  components: {
    TableHead,
    Board,
  },
  props: {
    dataList: Array,
  },
};
</script>
