<template>
  <TableViewWithTitle title="입찰 참여 업체">
    <template v-slot:headLeft>
      <p class="desc_tip tc_red">
        <span class="ico_purchase ico_exclam_r" />평가 정보는 관리자에게만 노출됩니다.
      </p>
    </template>
    <template v-slot:colgroup>
      <col style="width: 125px" />
      <col style="width: 192px" />
      <col style="width: 303px" />
      <col style="width: 192px" />
      <col style="width: 428px" />
    </template>
    <template v-slot:header>
      <th>상태</th>
      <th colspan="2">입찰내역</th>
      <th colspan="2">평가</th>
    </template>
    <template v-if="model.dataList.length <= 0" v-slot:body>
      <tr>
        <td class="align_center" colspan="5">입찰 참여 업체가 없습니다</td>
      </tr>
    </template>
    <template v-else v-slot:body>
      <ParticipatedCompanyOpenedItem
        v-for="item in model.dataList"
        :key="item.userNum"
        :model.sync="item"
        :isOnlyPriceEvaluate="isOnlyPriceEvaluate"
        :isBidChoiced="isBidChoiced"
        @onClickPopPartner="onClickPopPartner"
      />
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Textarea from "@/components/common/textarea/Textarea";
import FileView from "@/components/common/file/FileView";

import ParticipatedCompanyOpenedItem from "./ParticipatedCompanyOpenedItem";

import ParticipatedCompanyOpenedModel from "@/components/admin/bid/view/ParticipatedCompanyOpenedModel";

export default {
  name: "ParticipatedCompanyOpened",
  components: {
    TableViewWithTitle,
    Radio,
    SelectBox,
    Textarea,
    FileView,

    ParticipatedCompanyOpenedItem,
  },
  props: {
    model: ParticipatedCompanyOpenedModel,
    isOnlyPriceEvaluate: Boolean,
    isBidChoiced: Boolean,
  },
  methods: {
    onClickSelection() {
      this.$emit("onClickSelection");
    },
    onClickPopPartner(itemModel) {
      this.$emit("onClickPopPartner", itemModel);
    },
  },
};
</script>
