<template>
  <Popup popupType="tableType" width="824px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">개봉</h3>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 608px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>개봉자</th>
              <td colspan="3">
                {{ loginIdWithName }}
              </td>
            </tr>
            <tr>
              <th>개봉사유</th>
              <td class="h96">
                <Textarea
                  :maxLength="MAX_LENGTH_REASON"
                  :value.sync="text"
                  placeholder="개봉 사유를 입력하세요."
                  :isFocusOnMount="true"
                />
              </td>
            </tr>
          </template>
        </TableView>
        <div class="tbl_foot">
          <p class="desc_tip">
            개봉 버튼 선택 시, 블라인드 처리 된 입찰 상세 정보 확인이 가능합니다.
          </p>
        </div>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">취소</button>
      <button type="submit" class="btn_secondary btn_medium" @click="onClickSubmit">개봉</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import Textarea from "@/components/common/textarea/Textarea";

import { MAX_LENGTH_REASON } from "@/constants/commonTextLength";

export default {
  name: "ReleaseReasonPopup",
  components: {
    Popup,
    TableView,
    Textarea,
  },
  props: {
    loginIdWithName: String,
  },
  data() {
    return {
      text: "",
    };
  },
  computed: {
    MAX_LENGTH_REASON() {
      return MAX_LENGTH_REASON;
    },
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickSubmit() {
      this.$emit("onClickSubmit", this.text);
    },
  },
};
</script>
