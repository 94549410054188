<template>
  <TableViewWithTitle title="입찰공고 정보">
    <template v-slot:body>
      <tr>
        <th>입찰 공고번호</th>
        <td>{{ dataProps.postCid }}</td>
        <th>입찰 시작일</th>
        <td>{{ startDate }}</td>
      </tr>
      <tr>
        <th>입찰 공고명</th>
        <td>
          <router-link :to="toPath" class="link_subject" target="_blank" rel="opener">
            {{ dataProps.title }}
          </router-link>
        </td>
        <th>입찰 마감일</th>
        <td>{{ endDate }}</td>
      </tr>
      <tr>
        <th>분류</th>
        <td colspan="3">
          {{ category }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import { getDateString, getDateStringWithTime } from "@/utils/dateUtils";
import { makePathWithQuery } from "@/utils/urlUtils";
import { getCategoryDepthText } from "@/utils/spUtils";

export default {
  name: "AnnounceInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    dataProps: Object,
  },
  computed: {
    toPath() {
      const { postCid } = this.dataProps;
      const path = makePathWithQuery(this.$routerPath.ANNOUNCE_VIEW, postCid, this.$route.query);
      return path;
    },
    startDate() {
      if (!this.dataProps || !this.dataProps.startDate) return "";
      return getDateStringWithTime(this.dataProps.startDate);
    },
    endDate() {
      if (!this.dataProps || !this.dataProps.endDate) return "";
      return getDateStringWithTime(this.dataProps.endDate);
    },
    // 물품,하드웨어,서버 -> 물품>하드웨어>서버
    category() {
      if (!this.dataProps || !this.dataProps.category) return "";
      return getCategoryDepthText(this.dataProps.category);
    },
  },
};
</script>
