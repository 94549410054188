<template>
  <div class="group_form">
    <SelectBox
      class="w120"
      :dataList="dataList"
      :value.sync="selectedValue"
      :isDisabled="isDisabledSelectBox"
      :placeholder="placeholder"
      @update:value="onChangeSelect"
    />
    <InputNumber
      v-if="isDirectMode"
      class="w120"
      :value.sync="valueSync"
      :isDisabled="isDisabledSelectBox"
      :maxLength="3"
      :maxValue="100"
    />
  </div>
</template>

<script>
import SelectBox from "./SelectBox";
import InputNumber from "@/components/common/input/InputNumber";

export default {
  // tableView에 들어가는 점수 + 직접입력시 tf
  name: "SelectBoxScoreGroup",
  components: {
    SelectBox,
    InputNumber,
  },
  props: {
    value: String,
    placeholder: String,
    isDisabledSelectBox: {
      type: Boolean,
      default: false,
    },
    dataList: Array,
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {
    isDirectMode() {
      return this.selectedValue === "직접입력";
    },
    valueSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  mounted() {
    this.setValueByProps();
  },
  methods: {
    onChangeSelect(value) {
      if (this.isDirectMode) {
        this.$emit("update:value", "");
      } else {
        this.$emit("update:value", value);
      }
    },
    setValueByProps() {
      let toSelectedValue;

      if (this.value !== "0" && !this.value) {
        toSelectedValue = null;
      } else {
        const obj = this.dataList.find((item) => item.code === this.value);

        toSelectedValue = obj ? obj.code : "직접입력";
      }

      if (this.selectedValue !== toSelectedValue) {
        this.selectedValue = toSelectedValue;
      }
    },
  },
};
</script>
