<template>
  <Popup popupType="tableType" width="940px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">파트너 정보</h3>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 266px" />
            <col style="width: 192px" />
            <col style="width: 266px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>상호</th>
              <td colspan="3" class="td_text">
                {{ itemData.companyName || "" }}
              </td>
            </tr>
            <tr>
              <th>사업자 번호</th>
              <td>{{ itemData.corporateNum || "" }}</td>
              <th>대표자</th>
              <td>{{ itemData.ceo || "" }}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td colspan="3" class="td_text">
                {{ addressFull }}
              </td>
            </tr>
            <tr>
              <th>대표 전화번호</th>
              <td>{{ itemData.tel || "" }}</td>
              <th>대표 이메일</th>
              <td>{{ itemData.email || "" }}</td>
            </tr>
            <tr>
              <th>파트너 신청일</th>
              <td>{{ regDate }}</td>
              <th>상태 변경일</th>
              <td>{{ statusDate }}</td>
            </tr>
            <tr>
              <th>업태</th>
              <td class="td_text">
                {{ itemData.business || "" }}
              </td>
              <th>업종</th>
              <td class="td_text">
                {{ itemData.businessType || "" }}
              </td>
            </tr>
            <tr>
              <th>사업자등록증</th>
              <td>
                <FileView :dataList="itemData.businessRegFile ? [itemData.businessRegFile] : []" />
              </td>
              <th>통장사본</th>
              <td>
                <FileView :dataList="itemData.bankBookFile ? [itemData.bankBookFile] : []" />
              </td>
            </tr>
            <tr>
              <th>첨부 파일</th>
              <td colspan="3">
                <FileView :dataList="itemData.attachFileList || []" />
              </td>
            </tr>
            <tr>
              <th>참고 URL</th>
              <td colspan="3" class="td_text">
                {{ itemData.siteUrl || "" }}
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">파트너 추가 정보</h3>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 266px" />
            <col style="width: 192px" />
            <col style="width: 266px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>신용 평가 등급</th>
              <td>{{ addInfo.creditGrade || "" }}</td>
              <th>등록 점수</th>
              <td>{{ addInfo.score || "" }}</td>
            </tr>
            <tr>
              <th>현금 흐름 등급</th>
              <td>{{ addInfo.cashGrade || "" }}</td>
              <th>대표 취급 품목</th>
              <td class="td_text">
                {{ addInfo.categoryItem || "" }}
              </td>
            </tr>
            <tr>
              <th>첨부 파일</th>
              <td colspan="3">
                <!-- 파일 체크 -->
                <FileView :dataList="addInfo.fileList || []" />
              </td>
            </tr>
            <tr>
              <th>비고</th>
              <td colspan="3" class="h96 td_text">
                {{ addInfo.note || "" }}
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import FileView from "@/components/common/file/FileView";
import ApiService from "@/services/ApiService";
import { getAddressTextFull } from "@/utils/spUtils";
import { getDateString } from "@/utils/dateUtils";

export default {
  name: "BidPartnerPopup",
  components: {
    Popup,
    TableView,
    FileView,
  },
  props: {
    userNum: String,
  },
  data() {
    return {
      itemData: {},
      addInfo: {},
    };
  },
  computed: {
    addressFull() {
      if (!this.itemData) return "";

      const { address, address2 } = this.itemData;
      const ret = getAddressTextFull(address, address2);
      return ret;
    },
    regDate() {
      if (!this.itemData || !this.itemData.regDate) return "";
      return getDateString(this.itemData.regDate);
    },
    statusDate() {
      if (!this.itemData || !this.itemData.statusDate) return "";
      return getDateString(this.itemData.statusDate);
    },
  },
  beforeMount() {
    const path = `${this.$apiPath.PARTNER}/${this.userNum}`;

    this.getData(path);
  },
  methods: {
    alert(text) {
      this.$emit("alert", text);
    },
    async getData(path) {
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.alert(result.text);
        return;
      }

      this.itemData = result.data;

      this.addInfo = result.data.partnerInfo;

      // const pathAdd = `${ this.$apiPath.PARTNER }/${ this.userNum }${ this.$apiPath.INFO }`;

      // this.getAddData( pathAdd );
    },
    // async getAddData( path ) {
    //   const result = await ApiService.shared.getData( path );
    //   // 데이터가 없을 때 500 error 발생.
    //   if( !result.data ) {
    //     // this.alert( result.text );
    //     return;
    //   }

    //   this.addInfo = result.data;
    // },
    onClickClose() {
      this.$emit("onClickClose");
    },
  },
};
</script>
