const scoreList = [
  { code: "0", desc: "0" },
  { code: "10", desc: "10" },
  { code: "20", desc: "20" },
  { code: "30", desc: "30" },
  { code: "40", desc: "40" },
  { code: "50", desc: "50" },
  { code: "60", desc: "60" },
  { code: "70", desc: "70" },
  { code: "80", desc: "80" },
  { code: "90", desc: "90" },
  { code: "100", desc: "100" },
  { code: "직접입력", desc: "직접입력" },
];

export { scoreList };
