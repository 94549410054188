const printcss = `@charset "utf-8";
/* reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,button{margin:0;padding:0}
fieldset,img{border:0 none}
dl,ul,ol,menu,li{list-style:none}
blockquote, q{quotes: none}
blockquote:before, blockquote:after,q:before, q:after{content:'';content:none}
input,select,textarea,button{vertical-align:middle}
input::-ms-clear{display:none}
button{border:0 none;background-color:transparent;cursor:pointer}
body{overflow-x:auto;min-width:1290px;background:#fff}
body,th,td,input,select,textarea,button{font-size:12px;line-height:1.5;font-family:'Noto Sans KR',AppleSDGothicNeo-Regular,'Malgun Gothic','맑은 고딕',sans-serif;color:#555;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none}
a{color:#555;text-decoration:none}
/* a:active, a:hover{text-decoration:underline} */
a:active{background-color:transparent}
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal}

/* global */
#kakaoIndex{overflow:hidden;position:absolute;left:-9999px;width:0;height:1px;margin:0;padding:0}
.ir_pm{display:block;overflow:hidden;font-size:0;line-height:0;text-indent:-9999px}
.ir_caption{overflow:hidden;width:1px;font-size:1px;line-height:0;text-indent:-9999px}
.screen_out{overflow:hidden;position:absolute;width:0;height:0;line-height:0;text-indent:-9999px}
.show{display:block}
.hide{display:none}

.page_print{width:994px;padding:48px 48px;page-break-after:always}
.page_print:before{display:block;padding-bottom:24px;font-weight:500;font-size:28px;line-height:41px;color:#555;content:'입찰 관리'}

.ico_purchase{display:none}
.ico_exclam_r{display:inline-block;width:11px;height:11px;margin:1px;border:1px solid #F8626C;border-radius:50%;vertical-align:top}
.ico_exclam_r:before{display:block;font-weight:700;font-size:10px;line-height:11px;color:#F8626C;text-align:center;content:'!'}
.ico_select{display:inline-block;vertical-align:top}
.ico_select:before{display:block;font-size:10px;line-height:5px;content:'v'}
.select_comm .is-disabled .ico_select:before{color:#f0f0f0}

.w80{width:80px !important}
.w120{width:120px !important}

/* -- color -- */
.tc_red{color:#F8626C !important}

/* -- 컨텐츠 -- */
.area_left{float:left}
.area_right{float:right}

/* -- 안내문구--  */
.desc_tip{display:inline-block;margin-top:5px;font-size:11px;line-height:18px;color:#888;vertical-align:top}
.desc_tip .ico_exclam_r{margin-right:3px}
.tbl_head .desc_tip{margin:14px 0 0 8px}
.tbl_head .desc_tip:first-child{margin:0}
.tbl_head .tit_tbl + .desc_tip{margin-top:0;padding-bottom:1px;line-height:16px;vertical-align:bottom}

/* -- group_form -- */
.group_form{overflow:hidden;font-size:0}
.group_form .txt_view{display:inline-block;font-size:12px;vertical-align:top}
.group_form .txt_view_r{display:inline-block;padding-left:8px;font-size:12px;line-height:32px;vertical-align:top}

/* -- badge -- */
.badge_state{display:inline-block;overflow:hidden;min-width:46px;height:22px;padding:0 12px;border-radius:12px;border:1px solid #e1e1e1;font-size:12px;line-height:22px;color:#555;vertical-align:top;text-align:center}
.badge_state.on{border-color:#bbb}
.badge_state.disabled{border-color:#e1e1e1;background-color:#fbfbfb;color:#888}

/* tbl_head */
.tbl_head{overflow:hidden;max-height:32px;padding-bottom:8px;font-size:0}
.tbl_head .area_left:only-child .tit_tbl{padding-top:0}
.tbl_head .tit_tbl{display:inline-block;padding-top:12px;font-weight:normal;font-size:14px;line-height:20px;color:#888;vertical-align:bottom}

/* -- table -- */
.area_fold + .area_view,
.area_fold_r + .area_view,
.wrap_print + .area_view,
.area_view + .area_view{margin-top:56px}
/* table 공통 */
.tbl_comm{border-top:1px solid #222}
.tbl_comm table{min-width:100%;border:0 none;border-collapse:collapse;border-spacing:0;table-layout:fixed}
.tbl_comm colgroup{display:none}
.tbl_comm thead{display:table-header-group}
.tbl_comm thead th{padding:15px 12px;border-bottom:1px solid #e1e1e1;font-weight:normal;font-size:12px;line-height:18px;background-color:#fbfbfb;color:#222}
.tbl_comm .align_mid{vertical-align:middle !important}
.tbl_comm .align_left{text-align:left !important}
.tbl_comm .align_center{text-align:center !important}
.tbl_comm .align_right{text-align:right !important}
.tbl_comm .td_ellip{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-break:break-all}
.tbl_comm .td_ellip .link_subject{overflow:hidden;text-overflow:ellipsis;text-align:left}
.tbl_comm .td_break{word-wrap:break-word;word-break:keep-all !important}

/* 뷰 table */
.tbl_view tbody{display:table-row-group}
.tbl_view tbody tr{height:1px}
.tbl_view tbody th{width:16%;height:inherit;padding:15px 24px;border:1px solid #e1e1e1;border-top:0 none;font-weight:normal;font-size:12px;line-height:18px;background-color:#fbfbfb;color:#222;text-align:left;vertical-align:top}
.tbl_view tbody th:first-child{border-left:0 none}
.tbl_view tbody th .txt_asterisk{display:inline-block;padding-left:4px;color:#F8626C;vertical-align:top}
.tbl_view tbody td{width:34%;height:inherit;padding:8px 24px 8px 23px;border-bottom:1px solid #e1e1e1;line-height:32px;vertical-align:top}
.tbl_view tbody td > .check_comm,
.tbl_view tbody td > .radio_comm{padding:7px 0}
.tbl_view tbody td .file_view{margin:8px -24px -8px 0}
.tbl_view tbody td .file_uploaded{height:100%;margin:0}
.tbl_view tbody td .textarea_comm{height:100%}
.tbl_view tbody td .link_subject{display:block;overflow:hidden;line-height:32px;color:#5551CE;text-align:left;text-decoration:underline;white-space:nowrap;text-overflow:ellipsis}
.tbl_view .td_hidden{background:url(/assets/images/bg_pattern.png);color:#bbb;text-align:center;vertical-align:middle}
.tbl_view thead + tbody th{width:16%}
.tbl_view thead + tbody td{width:27%}
.tbl_view thead + tbody td.align_mid{width:9%}
.tbl_view thead + tbody th+td+th+td:last-child{width:33%}

/* 리스트 table */
.tbl_list table{width:100%}
.tbl_list thead th:first-child,
.tbl_list tbody td:first-child{padding-left:24px}
.tbl_list thead th:last-child,
.tbl_list tbody td:last-child{padding-right:24px}
.tbl_list tbody td{height:32px;padding:8px 12px;border-bottom:1px solid #e1e1e1;line-height:18px;text-align:center;word-break:break-all;letter-spacing:-.02em}
.tbl_list tbody td .link_subject{display:block;width:100%;text-align:left;text-decoration:underline}
.tbl_list tbody td .link_point{font-weight:500;color:#5551CE}
/* 리스티 빈타입 */
.tbl_list .td_empty{overflow:hidden;line-height:32px}

/* file */
.file_view{padding:0}
.file_view .btn_file{display:block;width:100%;padding-left:0;border:0 none;font-size:15px;line-height:18px;text-indent:inherit;outline:none;text-align:left}
.file_view .ico_purchase{display:none}
.file_view .txt_file{display:block;font-size:15px;line-height:22px;color:#5551ce;text-decoration:underline;word-wrap:break-word;word-break:keep-all}

/* selectBox */
.select_comm{display:inline-block;width:100%;height:32px;vertical-align:top}
.select_comm .el-input{position:relative;width:100%}
.select_comm .el-input__inner{display:block;width:100%;height:32px;border-radius:2px;border:1px solid #e1e1e1;font-size:12px;line-height:32px;color:#555;box-sizing:border-box}
.select_comm .el-input__inner::-webkit-input-placeholder{color:#555}
.select_comm .el-input__inner::-moz-placeholder{color:#555}
.select_comm .el-input__inner:-ms-input-placeholder{color:#555}
.select_comm .el-input__inner::-ms-input-placeholder{color:#555}
/* 인풋박스 */
.select_comm .el-input--suffix .el-input__inner{padding:0 31px 0 11px}
/* 인풋박스_활성화 */
.select_comm .el-input.is-active .el-input__inner,
.select_comm .el-select .el-input.is-focus .el-input__inner,
.select_comm .el-input__inner:focus,
.select_comm .el-select .el-input__inner:focus{border-color:#555}
/* 인풋박스_disabled */
.select_comm .el-input.is-disabled .el-input__inner{border-color:#f0f0f0;background-color:#fbfbfb;color:#bbb}
.select_comm .el-input.is-disabled .el-input__inner::-webkit-input-placeholder{color:#bbb}
.select_comm .el-input.is-disabled .el-input__inner::-moz-placeholder{color:#bbb}
.select_comm .el-input.is-disabled .el-input__inner:-ms-input-placeholder{color:#bbb}
.select_comm .el-input.is-disabled .el-input__inner::-ms-input-placeholder{color:#bbb}
.select_comm .el-input.is-disabled .el-input__prefix{background-color:#fbfbfb}
/* 아이콘 */
.select_comm .el-input__prefix{position:absolute;top:1px;left:auto;right:1px;height:6px;padding:10px 11px;background-color:#fff}
.select_comm .el-input__suffix{display:none}
.select_comm .el-input__icon{line-height:32px}

/* radio */
.radio_comm{position:relative;display:block;min-width:inherit;min-height:inherit;margin:0;padding:0}
.radio_comm input{position:absolute;top:0;bottom:0;left:0;right:0;z-index:10;width:100%;height:100%;border:0 none;opacity:0.01;filter:alpha(opacity=1);cursor:pointer}
.radio_comm label{display:none}
.radio_comm .ico_radio{display:none}
.radio_comm input:checked + label{display:block;padding-left:0;font-size:12px;line-height:16px;color:#555;text-align:center}

/* textarea */
.textarea_comm textarea{width:100%;height:100%;padding:6px 12px;border:1px solid #E1E1E1;font-size:12px;line-height:20px;color:#555;outline:none;box-sizing:border-box;resize:none;vertical-align:top}
.textarea_comm textarea::-webkit-input-placeholder{color:#bbb}
.textarea_comm textarea:-moz-placeholder{color:#bbb}
.textarea_comm textarea::-moz-placeholder{color:#bbb}
.textarea_comm textarea:-ms-input-placeholder{color:#bbb}`;

export default printcss;
