<template>
  <fragment>
    <tr>
      <td rowspan="5" class="align_mid">
        <div class="group_form">
          <Radio
            :name="radioName"
            :dataList="radioDataList"
            :selectedId.sync="model.choiceYn"
            :isDisabled="isBidChoiced"
          />
        </div>
      </td>
      <th>업체명</th>
      <td>
        <button
          type="button"
          class="link_subject"
          @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${model.userNum}`)"
        >
          {{ model.companyName }}
        </button>
      </td>
      <th>가격</th>
      <td>
        <span v-if="isBidChoiced">{{ model.priceScore }}</span>
        <SelectBoxScoreGroup
          v-else
          placeholder="점수 선택"
          :dataList="scoreList"
          :value.sync="model.priceScore"
        />
      </td>
    </tr>
    <tr>
      <th>투찰 금액</th>
      <td>{{ model.price | currency }}</td>
      <th>기술</th>
      <td>
        <span v-if="isBidChoiced">{{ model.skillScore }}</span>
        <SelectBoxScoreGroup
          v-else
          placeholder="점수 선택"
          :dataList="scoreList"
          :value.sync="model.skillScore"
          :isDisabledSelectBox="isOnlyPriceEvaluate"
        />
      </td>
    </tr>
    <tr>
      <th>견적서</th>
      <td>
        <FileView :dataList="model.estimateFileList" />
      </td>
      <th rowspan="2">의견</th>
      <td rowspan="2" :class="isBidChoiced ? 'td_text' : ''">
        <span v-if="isBidChoiced">
          {{ model.opinion }}
        </span>
        <Textarea v-else :value.sync="model.opinion" placeholder="평가 의견을 입력하세요." />
      </td>
    </tr>
    <tr>
      <th>제안서</th>
      <td>
        <FileView :dataList="model.proposalFileList" />
      </td>
    </tr>
    <tr>
      <th>비고</th>
      <td colspan="3" class="td_text">
        {{ model.note }}
      </td>
    </tr>
  </fragment>
</template>

<script>
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import SelectBoxScoreGroup from "@/components/common/selectBox/SelectBoxScoreGroup";
import Textarea from "@/components/common/textarea/Textarea";
import FileView from "@/components/common/file/FileView";
import { scoreList } from "@/constants/score";
import { addCommaForMoney } from "@/utils/stringUtils";
import { getCountWithCurrency } from "@/utils/spUtils";

import ParticipatedCompanyOpenedItemModel from "@/components/admin/bid/view/ParticipatedCompanyOpenedItemModel";

export default {
  name: "ParticipatedCompanyOpenedItem",
  components: {
    Radio,
    SelectBox,
    SelectBoxScoreGroup,
    Textarea,
    FileView,
  },
  props: {
    model: ParticipatedCompanyOpenedItemModel,
    isOnlyPriceEvaluate: Boolean,
    isBidChoiced: Boolean,
  },
  data() {
    return {
      radioDataList: [
        { id: "Y", text: "선정" },
        { id: "N", text: "미선정" },
      ],
    };
  },
  computed: {
    radioName() {
      return "radio" + this._uid;
    },
    countWithCurrency() {
      return getCountWithCurrency(this.model.price, this.model.currency);
    },
    scoreList() {
      return scoreList;
    },
  },
  methods: {
    // onClickPopPartner() {
    //   this.$emit('onClickPopPartner', this.model);
    // },
  },
};
</script>
