<template>
  <TableViewWithTitle title="입찰 참여 업체">
    <template v-slot:header>
      <th colspan="2">입찰내역</th>
      <th colspan="2">평가</th>
    </template>
    <template v-if="dataList.length <= 0" v-slot:body>
      <tr>
        <td class="align_center" colspan="4">입찰 참여 업체가 없습니다</td>
      </tr>
    </template>
    <template v-else v-slot:body>
      <fragment v-for="item in dataList" :key="item.userNum">
        <tr>
          <th>업체명</th>
          <td class="align_mid td_text">
            <button type="button" class="link_subject" @click="onClickPopPartner(item)">
              {{ item.companyName }}
            </button>
          </td>
          <th>가격</th>
          <td class="td_hidden">개봉 후 열람 가능</td>
        </tr>
        <tr>
          <th>투찰 금액</th>
          <td class="td_hidden">개봉 후 열람 가능</td>
          <th>기술</th>
          <td class="td_hidden">개봉 후 열람 가능</td>
        </tr>
        <tr>
          <th>견적서</th>
          <td class="td_hidden">개봉 후 열람 가능</td>
          <th rowspan="2">의견</th>
          <td rowspan="2" class="td_hidden">개봉 후 열람 가능</td>
        </tr>
        <tr>
          <th>제안서</th>
          <td class="td_hidden">개봉 후 열람 가능</td>
        </tr>
        <tr>
          <th>비고</th>
          <td colspan="3" class="td_hidden">개봉 후 열람 가능</td>
        </tr>
      </fragment>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "ParticipatedCompanyClosed",
  components: {
    TableViewWithTitle,
  },
  props: {
    dataList: Array,
  },
  methods: {
    onClickPopPartner(item) {
      this.$emit("onClickPopPartner", item);
    },
  },
};
</script>
