class ParticipatedCompanyOpenedItemModel {
  constructor() {
    this.objRaw = {};

    this.companyName = "";
    this.estimateFileList = [];
    this.note = "";
    this.price = "";
    this.priceScore = "";
    this.skillScore = "";
    this.proposalFileList = [];

    this.tenderNum = "";
    this.userNum = "";

    this.opinion = "";

    this.currency = "";

    // this.choiceYn = false;
    this.choiceYn = "N";
  }

  setData(obj) {
    this.objRaw = obj;

    if (obj.companyName) this.companyName = obj.companyName;
    if (obj.note) this.note = obj.note;
    if (obj.price) this.price = obj.price;
    if (obj.priceScore || obj.priceScore === 0 || obj.priceScore === "0")
      this.priceScore = String(obj.priceScore);
    if (obj.skillScore || obj.skillScore === 0 || obj.skillScore === "0")
      this.skillScore = String(obj.skillScore);
    if (obj.proposalFileList) this.proposalFileList = obj.proposalFileList;

    if (obj.currency) this.currency = obj.currency;

    if (obj.estimateFile) {
      const arr = [];
      arr.push(obj.estimateFile);

      this.estimateFileList = arr;
    }

    // this.choiceYn = !!obj.choiceYn;
    this.choiceYn = obj.choiceYn === true || obj.choiceYn === "true" ? "Y" : "N";

    if (obj.tenderNum) this.tenderNum = obj.tenderNum;
    if (obj.userNum) this.userNum = obj.userNum;

    if (obj.opinion) this.opinion = obj.opinion;
  }

  getData() {
    let obj = {
      companyName: this.companyName,
      note: this.note,
      price: this.price,
      priceScore: this.priceScore,
      skillScore: this.skillScore,
      proposalFileList: this.proposalFileList,

      tenderNum: this.tenderNum,
      userNum: this.userNum,

      choiceYn: this.choiceYn === "Y",
      opinion: this.opinion,

      currency: this.currency,
    };

    if (this.estimateFileList && this.estimateFileList.length > 0) {
      obj.estimateFile = this.estimateFileList[0];
    }

    obj = Object.assign(this.objRaw, obj);

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}

export default ParticipatedCompanyOpenedItemModel;
