<template>
  <div class="area_view">
    <Board :disableHover="true" :dataList="postHistoryList" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 156px" />
        <col style="width: 224px" />
        <col style="width: 224px" />
        <col style="width: 636px" />
      </template>
      <template v-slot:tr>
        <th>항목</th>
        <th>일시</th>
        <th>아이디(이름)</th>
        <th>내용</th>
      </template>
      <template v-slot:rows>
        <tr v-for="(item, index) in postHistoryList" :key="index">
          <td>{{ item.statusName }}</td>
          <td>{{ getRegDate(item) }}</td>
          <td>{{ item | idWithNameByObj }}</td>
          <td class="align_left">
            {{ item.reason }}
          </td>
        </tr>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="6" class="td_empty">파트너 거래 현황이 없습니다.</td>
        </tr>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "@/components/common/board/Board";
import { getDateStringWithTime } from "@/utils/dateUtils";

export default {
  name: "AnnounceHistroy",
  components: {
    Board,
  },
  props: {
    postHistoryList: Array,
  },
  methods: {
    getRegDate(item) {
      return getDateStringWithTime(item.regDate);
    },
  },
};
</script>
