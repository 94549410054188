class OverallOpinionModel {
  constructor() {
    this.attachFileList = [];
    this.note = "";
  }

  setData(attachFileList, note) {
    if (attachFileList) this.attachFileList = attachFileList;
    if (note) this.note = note;
  }

  getData() {
    const obj = {
      note: this.note,
      attachFileList: this.attachFileList,
    };

    return obj;
  }

  getAlertMessage() {
    // if( !this.tenderPartner.companyName )  return '상호를 입력해주세요.';
    // if( !this.tenderPartner.ceo )  return '대표자를 입력해주세요.';
    // if( !this.tenderPartner.tel.getIsValid() )  return '전화번호를 입력해주세요.';
    // if( !this.tenderPartner.email.getIsValid() )  return '이메일을 입력해주세요.';
    // if( !this.tenderPartner.business )  return '업태를 입력해주세요.';
    // if( !this.tenderPartner.businessType )  return '업종을 입력해주세요.';

    return null;
  }
}

export default OverallOpinionModel;
