<template>
  <TableViewWithTitle title="평가방법 설정">
    <template v-slot:body>
      <tr>
        <th>업체 선정 방식</th>
        <td>
          <div v-if="getIsOnlyPriceEvaluate" class="group_form">
            <span class="txt_view w80">가격</span>
            <span class="txt_view">100</span>
            <span class="txt_view_r">%</span>
          </div>
          <div v-else class="group_form">
            <span class="txt_view w80">가격&#47;기술</span>
            <span class="txt_view">{{ dataProps ? dataProps.pricePercent : "" }}</span>
            <span class="txt_view_r">%</span>
            <span class="txt_view_c w40">&#47;</span>
            <span class="txt_view">{{ dataProps ? dataProps.skillPercent : "" }}</span>
            <span class="txt_view_r">%</span>
          </div>
        </td>
        <th>가격 공개 방식</th>
        <td>{{ priceTypeText }}</td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import { getEvaluateData, getIsOnlyPriceEvaluate } from "@/utils/spUtils";

export default {
  name: "EvaluationSetting",
  components: {
    TableViewWithTitle,
  },
  props: {
    dataProps: Object,
  },
  computed: {
    priceTypeText() {
      if (!this.dataProps || !this.dataProps.priceType) return "";
      const item = getEvaluateData(true, this.dataProps.priceType);
      return item.desc;
    },
    getIsOnlyPriceEvaluate() {
      if (!this.dataProps || !this.dataProps.choiceType) return true;

      const code = this.dataProps.choiceType;

      return getIsOnlyPriceEvaluate(code);
    },
  },
};
</script>
<style scoped>
.group_form .txt_view_r {
  line-height: 1.5;
}
</style>
