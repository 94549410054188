<template>
  <div class="area_view">
    <TableView>
      <template v-slot:colgroup>
        <col style="width: 192px" />
        <col />
      </template>
      <template v-slot:body>
        <tr>
          <th>종합의견</th>
          <td class="h96">
            <Textarea
              :value.sync="model.note"
              placeholder="평가 의견을 입력하세요."
              :isDisabled="isDisabled"
            />
          </td>
        </tr>
        <tr>
          <th>첨부 파일</th>
          <td>
            <FileView v-if="isDisabled" :dataList="model.attachFileList" />
            <FileWrite
              v-else
              :dataList="model.attachFileList"
              @fileUploaded="fileUploaded"
              @fileRemoved="fileRemoved"
            />
          </td>
        </tr>
      </template>
    </TableView>
  </div>
</template>

<script>
import TableView from "@/components/shared/tableView/TableView";
import Textarea from "@/components/common/textarea/Textarea";
import FileWrite from "@/components/common/file/FileWrite";
import FileView from "@/components/common/file/FileView";

import OverallOpinionModel from "@/components/admin/bid/view/OverallOpinionModel";

export default {
  name: "OverallOpinion",
  components: {
    TableView,
    Textarea,
    FileWrite,
    FileView,
  },
  props: {
    model: OverallOpinionModel,
    isDisabled: Boolean,
  },
  methods: {
    fileUploaded(files) {
      this.model.attachFileList = this.model.attachFileList.concat(files);
    },
    fileRemoved(itemToRemove) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== itemToRemove);
    },
  },
};
</script>
